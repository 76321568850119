<template>
    <div class="aboutUsSection">
        <div class="innerAboutUS">
            <div>
                <c-H1-43 :color="colorTheme['Primary-Cyan0400']"> About us</c-H1-43>
            </div>
            <div>
                <c-H4-22>We are a bunch of engineering, management and finance professionals;<br>

                        We seek better educational outcomes using technology.<br>

                        Join us in building this worldwide movement <br>

                        We are currently based in India <br>
                </c-H4-22>
            </div>
        </div>
    </div>
</template>

<script>
import { theme } from '../assets/theme'
import { H4_22, H1_43} from '../atoms/text_styles'
export default {
    name: 'aboutUs',
    components: {
        'c-H4-22': H4_22,
        'c-H1-43': H1_43
    },
    data(){
        return {
            colorTheme:theme
        }
    }

}
</script>

<style>

.aboutUsSection{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 100%;
    gap: 8px;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.innerAboutUS{
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 8px;
    align-items: center;

}
</style>