<template>
    <div class="tutor-invite-outer-div">
        <div class="tutor-invite-heading-div">
            <c-H1-43 :color="colorTheme['Primary-Cyan0400']"> Get going in 4 easy steps </c-H1-43>
        </div>
        <div class="tutor-invite-content-div">
            <div class="ti-card-div">
                <div class="ti-1-image">
                </div>
                <div class="titleDiv">
                    <c-H4-22 >You Upload exercises <br>Use pre built exercises 
                    </c-H4-22>
                </div>

            </div>
            <div class="ti-card-div">
                <div class="ti-2-image">
                </div>
                <div class="titleDiv">
                    <c-H4-22 > You Conduct live tutorial<br>Audio Video Chat and Interactive canvas
                    </c-H4-22>
                </div>
                
            </div>
            <div class="ti-card-div">
                <div class="ti-3-image">
                </div>
                <div class="titleDiv">
                    <c-H4-22 > Your Students do abhyas <br>Encourage your students to gain proficiency. Hand hold parents to create culture of Abhyas
                    </c-H4-22>
                </div>
                
            </div>
            <div class="ti-card-div">
                <div class="ti-4-image">
                </div>  
                <div class="titleDiv">
                    <c-H4-22 > You Review and grade <br> At your convenience
                    </c-H4-22>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

import { theme } from '../assets/theme'
import { H4_22, H1_43} from '../atoms/text_styles'
export default {
    name: 'tutorInvite',
    components: {
        'c-H4-22': H4_22,
        'c-H1-43': H1_43
    }, 
    data(){
        return {
            colorTheme:theme
        }
    }
}
</script>

<style>
.tutor-invite-outer-div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tutor-invite-heading-div {
    width: 100%;
}

.titleDiv{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 400px;
    align-items: center;
    justify-content: center;
}

.tutor-invite-content-div{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
}
.ti-card-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;
    background-color: #F8FFFF;
}

.ti-1-image {
    width: 400px;
    height: 400px;
    background-image: url('../assets/images/1-upload-lr.png');
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
    background-position: center; 
    aspect-ratio: 5 / 2; 
}

.ti-2-image {
    width: 400px;
    height: 400px;
    background-image: url('../assets/images/2-live-blend-lr.png');
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
    background-position: center; 
    aspect-ratio: 5 / 2; 
}

.ti-3-image {
    width: 400px;
    height: 400px;
    background-image: url('../assets/images/3-abhyas-lr.png');
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
    background-position: center;
    aspect-ratio: 5 / 2; 
}

.ti-4-image {
    width: 400px;
    height: 400px;
    background-image: url('../assets/images/c5.png');
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
    background-position: center; 
    aspect-ratio: 5 / 2; 
}

@media (min-width: 561px) and  (max-width: 920px){
    .ti-1-image, .ti-2-image, .ti-3-image, .ti-4-image {
        height: 400px;
    }
}

@media (max-width: 560px){
    .ti-1-image, .ti-2-image, .ti-3-image, .ti-4-image {
        height: 400px;
        width: 100%;
    }
    .ti-card-div{
        display: flex;
        flex-direction: column;
    }
    .titleDiv{
        width: 100%;
        height: auto !important;
        align-items: center;
    }
}

</style>