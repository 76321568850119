<template>
    <div >
        <div v-if="!isAuth">
            <UnsignedHome ></UnsignedHome>
        </div>
        <div v-if="isAuth">
            <userDash></userDash>
            <!--<c-frame-55>
                <c-loader-div></c-loader-div>
            </c-frame-55>-->
        </div>
    </div>
</template>

<script>
    import UnsignedHome from '../pages/unsignedHome_page.vue';
    import userDash from '../pages/userDash.vue';
    import AppConfig from '../../config.js';
    import axios from 'axios';
    //import Keycloak from '../KC_auth';
    //import {frame_55, loaderDiv } from '../atoms/div_styles';
    export default {
        name: 'appHome',
        components:{
            UnsignedHome,
            userDash
            //'c-loader-div': loaderDiv,
            //'c-frame-55': frame_55
        },
        data(){
            return{
                isAuth: false,
                UI_baseURL: AppConfig.UI_Base_URL,
                auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
                get_acc_token: "",
                get_id_token: "",
                get_refresh_token: "",
                //login_url: "",
                roleOfUser: ""
            }
        },
        async beforeMount(){
            this.isAuth = this.$keycloak.authenticated
            /*if(this.$keycloak.tokenParsed != undefined){
                this.roleOfUser = this.$keycloak.tokenParsed.primaryRole
            }
            //await this.primaryRoleOfUser()
            console.log("roleOfUser",this.roleOfUser);
            if((this.roleOfUser === 'Admin') || (this.roleOfUser === "Auditor") || (this.roleOfUser === "Tutor")){
                window.location.href = this.UI_baseURL + "/admin/dashBoard"
            }
            if(this.roleOfUser === "Protege"){
                window.location.href = this.UI_baseURL + "/dashBoard"
            }
            //await this.getUserAuthInfo()
            console.log("URL",this.UI_baseURL);
            console.log("isAuth",this.isAuth);
            if(this.roleOfUser === undefined){
                window.location=this.UI_baseURL + "/me/primary_role"
            }*/
        },
        methods: {
            /**
             * 
             * @deprecated This function is deprecated and it can be removed.
             * We are not using this API calls any more
             */
            async primaryRoleOfUser(){
                try{
                    let allRolesResp = await axios.get(this.auth_api_Base_URL + "/v1_0/me/primary_role")
                    console.log("get primary role", allRolesResp)
                    this.roleOfUser = allRolesResp.data.primary_role
                }catch(error){
                    console.log("error in resp pf primary role:", error.response)
                }
            },
            async getUserAuthInfo(){
                try{
                let resp = await axios.get(this.auth_api_Base_URL + "/v1_0/me")
                console.log("get user resp", resp)
                if(resp.headers["status_code"] === "200"){
                    this.isAuth = true
                }
                else{
                    this.isAuth = false
                }
                }catch(error){
                    console.log("error in resp of getting user info:", error.response)
                    this.isAuth = false
                }
            }
        }
    }
</script>

<style >

</style>
