import { io } from 'socket.io-client';
import AppConfig from '../../config.js';

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection(token) {
    this.socket = io(AppConfig.Socket_Base_URL, {
        // httpCompression: false,
        auth: {
            token: token
        },
        transports: ['websocket']
    });
  }
}

export default new SocketioService();